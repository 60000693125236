<template>
  <div class="titlesTop">
    <headTitle title="我的心愿" />
    <mescroll-vue class="mescroll gundong" ref="mescroll" :up="mescrollUp">
      <!-- <div :id="'activeDemand'+id"></div> -->
      <div v-if="emptyShow" class="empty_sign">暂无数据~</div>
      <div v-else>
        <div
          class="activeContent"
          v-for="(item,index) in dataLink"
          :key="index"
          @click="activeDetail(item)"
        >
          <div class="demandList">
            <div class="left_title">
              <img src="../../assets/images/headStart.png" alt />
              <span>{{item.wishTypeName}}</span>
            </div>
            <!-- 状态 -->
            <van-button v-if="item.status === 3" type="primary" round size="small">已对接</van-button>
            <van-button v-else type="danger" round size="small">未对接</van-button>
          </div>
          <!-- 显示内容 -->
          <div class="demandContent">
            <p>
              <span>许愿群众</span>
              <span>{{item.memberName}}</span>
            </p>
            <p>
              <span>心愿描述</span>
              <span>{{item.description}}</span>
            </p>
            <div class="bottom_det">
              <p>查看详情</p>
              <i class="iconfont icon-fanhui-copy"></i>
            </div>
          </div>
        </div>
      </div>
    </mescroll-vue>
    <!-- <div v-else>暂无数据</div> -->
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue"; //上拉加载，下拉刷新
import wishApi from "@/api/wish";
import { Toast } from "vant";
import HeadTitle from '../globalcomponents/headTitle.vue';
export default {
  data() {
    return {
      dataLink: [], //数据
      mescrollUp: {
        callback: this.activeData,
        page: {
          num: 0,
          size: 10,
        },
        htmlNodata: '<p class="upwarp-nodata">-- 到底啦 --</p>',
        noMoreSize: 5,
        auto: true,
        lazyLoad: {
          use: true,
        },
      },
      mescroll: null,
      memberId: '',
      emptyShow: false,  // 根据数据是否为空显示
    }
  },
  created() {
    if (window.localStorage.personalInformation && window.localStorage.personalInformation != 'undefined') {  // 会员登入
      this.memberId = JSON.parse(window.localStorage.personalInformation).id;
    }
  },
  components: {
    MescrollVue
  },
  methods: {
    // 查询我的心愿
    activeData(page, mescroll) {
      // myWishData
      let params = { // app名字
        memberId: this.memberId,
        page: page.num,
        size: page.size
      }
      wishApi.myWishData(params).then(res => {
        if (res.success) {
          if (page.num === 1) {
            this.dataLink = [];
          }
          this.dataLink = this.dataLink.concat(res.data.items);
          if (this.dataLink.length === 0) {
            this.emptyShow = true;
          } else {
            this.emptyShow = false;
          }
          mescroll.endSuccess(res.data.items.length);
        } else {
          mescroll.endErr();
        }
      }).catch((err) => {
        mescroll.endErr();
      });

      // this.$http
      //   .get(
      //     this.javalinkTwo + `app/ap_member//member/wish/query_my_wish_list?memberId=${this.memberId}&page=${page.num}&size=${page.size}`,
      //     {
      //       headers: {
      //         Authorization: window.localStorage.token
      //       },
      //     })
      //   .then((res) => {
      //     console.log(res.data);
      //     if (res.data.success) {
      //       if (page.num === 1) {
      //         this.dataLink = [];
      //       }
      //       this.dataLink = this.dataLink.concat(res.data.data.items);
      //       if(this.dataLink.length === 0) {
      //         this.emptyShow = true;
      //       } else {
      //         this.emptyShow = false;
      //       }
      //       mescroll.endSuccess(res.data.data.items.length);
      //     } else {
      //       mescroll.endErr();
      //     }
      //   })
      //   .catch((err) => {
      //     mescroll.endErr();
      //   });
    },
    activeDetail(item) {
      var link = window.location.href.split("#/")[0] + "#/detail?id=" + item.id; //链接
      window.location.href = link;
    },
  }
}
</script>

<style lang="less" scoped>
.activeDownUp {
  width: 100%;
  height: 74vw;
}
.activeContent {
  width: 92vw;
  height: auto;
  border-radius: 4px;
  margin: 10px auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
  box-sizing: border-box;
  .demandList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    // border-bottom: 1px solid #E8E8E8;
    .left_title {
      display: flex;
      align-items: center;
      width: 66vw;
      img {
        width: 7vw;
        margin-right: 3vw;
      }
      span {
        font-size: 0.31rem;
      }
    }
  }
  .demandContent {
    padding-top: 10px;
    p {
      line-height: 0.4rem;
      font-size: 0.32rem;

      span {
        &:nth-child(1) {
          color: #cecece;
          margin-right: 3vw;
        }
        &:nth-child(2) {
          display: inline-block;
          width: 50vw;
        }
      }
    }
    .bottom_det {
      margin-top: 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        color: #cecece;
      }
    }
  }
}
.empty_sign {
  text-align: center;
  font-size: 0.4rem;
  margin-top: 5vw;
}
</style>